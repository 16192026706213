export const useAuthStore = defineStore({
    id: "useAuthStore",
    state: () => ({
        me: {
            id: null,
            username: null,
            email: null,
            fullname: null,
            whatsapp: null,
            about: null,
            status: null,
            avatar: null,
            role: null,
            created_at: null,
            updated_at: null,
            meta: null,
            token: null,
            vendor: {
                vendor_code: "",
                name: "",
            },
        },
        load: false,
        relogin: false,
        no_vendor: false,
    }),
    getters: {
        loading: (state) => state.load,
        meValue: (state) => state.me,
    },
    actions: {
        async GetMe() {
            const { data, status } = await RFetch(`/base/auth/me`);
            if (status === "error") {
                this.relogin = true;
            } else {
                this.relogin = false;
            }
            if (data) {
                if (data?.username === null) {
                    window.location.href = "/logout";
                }
                if (data?.vendor === null) {
                    this.no_vendor = true;
                } else {
                    this.no_vendor = false;
                }
                this.me = data?.data;
            } else {
                // window.location.href = '/logout'
            }
        },
    },
    hydrate(state, initialState) {
        // if (process.client) {
        //     state.email = useLocalStorage('pinia/auth/email', '').value;
        // }
    },
});
